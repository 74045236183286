exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-what-we-offer-building-mvp-tsx": () => import("./../../../src/pages/what-we-offer/building-mvp.tsx" /* webpackChunkName: "component---src-pages-what-we-offer-building-mvp-tsx" */),
  "component---src-pages-what-we-offer-fractional-cto-tsx": () => import("./../../../src/pages/what-we-offer/fractional-cto.tsx" /* webpackChunkName: "component---src-pages-what-we-offer-fractional-cto-tsx" */),
  "component---src-pages-what-we-offer-hire-tech-talent-tsx": () => import("./../../../src/pages/what-we-offer/hire-tech-talent.tsx" /* webpackChunkName: "component---src-pages-what-we-offer-hire-tech-talent-tsx" */)
}

